<template>
  <div class="bg-white">
    <div class="container text-center">
      <a name="services" id="servicesAnchor"> </a>
      <h2 class="text-dark section1Title" v-html="$t('Buy.Content.BuyingCarWithPash1')" />
    </div>
    <div class="container">
      <div class="with-pash-section-wrapper mt-5 column">
        <div class="with-pash-section">
          <div class="row px-3 px-md-0">
            <div class="column-img col-md-12 col-4">
              <b-img :src="findYourCar" />
            </div>
            <div class="col-md-12 col-8 text-sm-left">
              <h5 class="new-h5">
                1. {{ $t("Buy.Content.FindYourCar") }}
                <!-- Find your car -->
              </h5>
              <small>
                {{ $t("Buy.Content.PashGivesAccess") }}
                <!-- Pash gives you access to a wide selection of vehicles from
                various countries, all in one place. -->
              </small>
            </div>
          </div>
        </div>
        <div class="with-pash-section">
          <div class="row px-3 px-md-0">
            <div class="column-img col-md-12 col-4">
              <b-img :src="reserveIt" />
            </div>
            <div class="col-md-12 col-8 text-sm-left">
              <h5 class="new-h5">
                2. {{ $t("Buy.Content.ReserveIt") }}
                <!-- Reserve it -->
              </h5>
              <small>
                {{ $t("Buy.Content.ReservationEnsures") }}
                <!-- The reservation ensures you get priority on your chosen vehicle. -->
                <a href="#searchAnchor"
                  >{{ $t("Buy.Content.ReservationPolicy") }}
                  <!-- See our reservation policy. -->
                </a>
              </small>
            </div>
          </div>
        </div>
        <div class="with-pash-section">
          <div class="row px-3 px-md-0">
            <div class="column-img col-md-12 col-4">
              <b-img :src="confirmReservation" />
            </div>
            <div class="col-md-12 col-8 text-sm-left">
              <h5 class="new-h5">
                3. {{ $t("Buy.Content.ConfirmReservation") }}
                <!-- Confirm reservation -->
              </h5>
              <small>
                {{ $t("Buy.Content.PashProvidesYou") }}
                <!-- Pash provides you with a clear and accurate inspection report to
                help you decide. No pressure. -->
                <a href="#searchAnchor">{{ $t("Buy.Content.Details") }}. </a>
              </small>
            </div>
          </div>
        </div>
        <div class="with-pash-section">
          <div class="row px-3 px-md-0">
            <div class="column-img col-md-12 col-4">
              <b-img :src="paymentDelivery" />
            </div>
            <div class="col-md-12 col-8 text-sm-left">
              <h5 class="new-h5">
                4. {{ $t("Buy.Content.PaymentAndDelivery") }}
                <!-- Payment &amp; Delivery -->
              </h5>
              <small>
                {{ $t("Buy.Content.OncePaymentIsProcessed") }}
                <!-- Once payment is processed, we deliver the vehicle to your
                selected destination, 100% Guaranteed. -->
                <a href="#searchAnchor">{{ $t("Buy.Content.Details") }}.</a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center whiteShadow">
      <div class="button-wrapper">
        <router-link to="search" pill class="section1button mx-auto btn-primary">
          <span>{{ $t("Buy.Content.FindYourCar") }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      findYourCar: require("@/assets/img/icons/find_your_car.svg"),
      reserveIt: require("@/assets/img/icons/reserve_it.svg"),
      confirmReservation: require("@/assets/img/icons/review_the_report.svg"),
      paymentDelivery: require("@/assets/img/icons/payment_delivery.svg"),
    };
  },
  props: {
    text: {
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
.section1Title {
  padding-top: 100px;
  margin-bottom: 90px;
  font-size: 38px;
  letter-spacing: -0.5px;
}
.button-wrapper {
  padding-top: 48px;
  padding-bottom: 69px;
  display: block;
}
.section1button {
  padding: 16px 52px;
  width: 340px;
  box-shadow: none;
  color: white !important;
  border-radius: 45px;
  text-decoration: none !important;
  transition: 0.3s;
}
.section1button span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
@media (max-width: 991px) {
  .section1Title {
    padding-top: 50px;
    margin-bottom: 32px;
    font-size: 34px;
    line-height: 48px;
  }
  .button-wrapper {
    padding-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .section1Title {
    padding-top: 32px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
  .column h5 {
    padding: 0;
  }
  .section1button {
    padding: 12px 52px;
  }
}
.with-pash-section-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1199px) {
    column-gap: 24px;
    row-gap: 30px;
  }
}
.with-pash-section {
  width: 250px;
  @media (max-width: 1199px) {
    width: calc((100% / 2) - 12px);
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
<style>
.with-pash-section small {
  font-size: 14px;
}
.with-pash-section small a {
  display: none;
}
</style>
