import { render, staticRenderFns } from "./TheHomePageSection1.vue?vue&type=template&id=2dd47c90&scoped=true&"
import script from "./TheHomePageSection1.vue?vue&type=script&lang=js&"
export * from "./TheHomePageSection1.vue?vue&type=script&lang=js&"
import style0 from "./TheHomePageSection1.vue?vue&type=style&index=0&id=2dd47c90&prod&scoped=true&lang=scss&"
import style1 from "./TheHomePageSection1.vue?vue&type=style&index=1&id=2dd47c90&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd47c90",
  null
  
)

export default component.exports